import Vue from 'vue';
import Vuex, { Store } from 'vuex';

import academy from './modules/academy';
import awards from './modules/awards';
import blog from './modules/blog';
import chat from './modules/chat';
import cleverbridge from './modules/cleverbridge';
import config from './modules/config';
import countries from './modules/countries';
import events from './modules/events';
import eventsFeed from './modules/events-feed';
import expressSignup from './modules/express-signup';
import geolocation from './modules/geolocation';
import inapp from './modules/inapp';
import job from './modules/job';
import licenses from './modules/licenses';
import locales from './modules/locales';
import pages from './modules/pages';
import partners from './modules/partners';
import partnersLocator from './modules/partners-locator';
import popup from './modules/popup';
import pressReleases from './modules/press-releases';
import products from './modules/products';
import publications from './modules/publications';
import resourceCenter from './modules/resource-center';
import ribbon from './modules/ribbon';
import sense from './modules/sense';
import seo from './modules/seo';
import slapfive from './modules/slapfive';
import slices from './modules/slices';
import solutions from './modules/solutions';
import trial from './modules/trial';
import user from './modules/user';
import vroi from './modules/vroi';

interface AugmentedStore {
    $context: Record<string, any>;
}

Vue.use(Vuex);

export function StoreFactory(context) {
    const store = new Vuex.Store({
        // Can't use strict due to manual state mutations during .dataFrom resolve
        // strict: process.env.NODE_ENV === 'development',

        // WARN: context is needed for proper hydration, but NEVER expose anything but `.public` here
        state() {
            return {
                context: {
                    public: context.public,
                },
            };
        },

        // TODO: should we only expose modules needed for a particular site_id?
        modules: {
            academy,
            awards,
            blog,
            chat,
            cleverbridge,
            config,
            countries,
            events,
            eventsFeed,
            expressSignup,
            geolocation,
            inapp,
            job,
            licenses,
            locales,
            pages,
            partners,
            partnersLocator,
            popup,
            pressReleases,
            products,
            publications,
            resourceCenter,
            ribbon,
            sense,
            seo,
            slapfive,
            slices,
            solutions,
            trial,
            user,
            vroi,
        },
    });

    const augmented = store as Store<any> & AugmentedStore;
    augmented.$context = context;
    return augmented;
}
