// TODO: it shouldn't be here, at least not like this

import { parseISO } from 'date-fns';
import { format, formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import { formatUsingTimeZone, formatToLocaleDate } from '@utils/locales';

/**
 * Returns an object containing the formatted time and timezone offset for a given date string and locale.
 * The function must be called client-side.
 *
 * @param {string} dateString - The date string to format.
 * @param {string} locale - The locale to use for formatting the time.
 * @returns {{offset: string, time: string}} - An object containing the formatted time and timezone offset.
 */
function getFormattedZonedTime(dateString, locale) {
    const utcDate = new Date(dateString);
    const time = formatToLocaleDate(utcDate.toISOString(), locale);
    let offset = utcDate.getTimezoneOffset() / -60;

    offset = offset < 0 ? `${offset}` : `+${offset}`;

    return { time, offset };
}

/**
 * Returns an object containing the formatted time and offset for a provided timezone.
 * @param {string} date - The date string to format.
 * @param {string} timezone - The timezone to format the date in.
 * @param {string} locale - The locale to use for formatting the time.
 * @param {string} formatString - Optional format string for the date.
 * @returns {{offset: string, time: string}} - An object containing the formatted time and offset.
 */
function getFormattedLocalTime(date, timezone, locale, formatString) {
    const utcDate = new Date(date);
    const time = formatString
        ? formatInTimeZone(utcDate, timezone, formatString)
        : formatUsingTimeZone(utcDate, timezone, locale);

    const offsetInHours = Math.round(getTimezoneOffset(timezone, utcDate) / (1000 * 60 * 60));
    const offset = offsetInHours < 0 ? `${offsetInHours}` : `+${offsetInHours}`;

    return { time, offset };
}

export default {
    getEventType(event) {
        if (event.is_in_person && event.is_virtual) return 'hybrid';
        if (event.is_in_person) return 'in-person';
        if (event.is_virtual) return 'virtual';
        if (event.is_on_demand) return 'on-demand';
        if (event.is_past) return 'past';
        return null;
    },

    getActualOccurrence(event) {
        if (event.is_on_demand) return null;

        if (!(Array.isArray(event.occurrences) && event.occurrences.length)) return null;

        const getOccurrence = (occurrences, isPast) => {
            const today = new Date();
            const sortedOccurrences = occurrences
                .filter((a) => (isPast ? a.date < today : a.date > today))
                .sort((a, b) => (isPast ? b.date - a.date : a.date - b.date));
            if (sortedOccurrences.length) {
                return sortedOccurrences[0];
            }
            return null;
        };

        const mappedOccurrences = event.occurrences.map((a) => ({
            ...a,
            date: new Date(a.begins_at),
        }));

        let occurrence = getOccurrence(mappedOccurrences, event.is_past);

        // if there are no occurrences in the future for non-past events
        // get occurrence in the past
        if (!occurrence && !event.is_past) {
            occurrence = getOccurrence(mappedOccurrences, true);
        }

        return occurrence;
    },

    /**
     * Converts a raw date into a formatted date object based on the event type and timezone.
     *
     * @param {any} rawDate - The raw date to convert.
     * @param {string} eventType - The type of the event.
     * @param {string} eventTimeZone - The timezone of the event.
     * @param {string} [locale='en-us'] - The locale for formatting.
     * @param {string} [formatString=''] - The format string for the date.
     * @return {Object} The formatted date object with time, type, and timezone offset.
     */
    convertDate(rawDate, eventType, eventTimeZone, locale = 'en-us', formatString = '') {
        const date = {
            time: null,
            type: null,
            timezoneOffset: null,
        };

        if (!rawDate) return date;

        if (['in-person', 'hybrid', 'past', 'on-demand'].includes(eventType)) {
            const formattedDate = getFormattedLocalTime(rawDate, eventTimeZone, null, formatString);

            date.rawDate = new Date(rawDate);
            date.time = formattedDate.time;
            date.formatted = formattedDate.time;
            date.type = 'local';
            date.timezoneOffset = formattedDate.offset;
        }

        if (eventType === 'virtual') {
            const formattedDate = getFormattedZonedTime(rawDate, locale, formatString);

            date.rawDate = new Date(rawDate);
            date.formatted = formattedDate.time;
            date.type = 'virtual';
            date.timezoneOffset = formattedDate.offset;
            if (formatString) date.formatted = this.formatDate(rawDate, formatString);
        }

        return date;
    },

    calculateDurationToString(start, end) {
        const pluralize = (count, noun, suffix = 's') => (
            count > 0 ? `${count} ${noun}${count !== 1 ? suffix : ''}` : ''
        );

        const dateStart = new Date(start);
        const dateEnd = new Date(end);

        const seconds = Math.floor((dateEnd.getTime() - dateStart.getTime()) / 1000);
        let minutes = Math.floor(seconds / 60);

        if (minutes === 60) {
            return '1 hour';
        }

        if (minutes <= 120) {
            return pluralize(minutes, 'minute');
        }

        const hours = Math.floor(minutes / 60);
        minutes -= hours * 60;

        if (hours <= 8) {
            return `${pluralize(hours, 'hour')} ${minutes > 0 ? pluralize(minutes, 'minute') : ''}`;
        }

        const days = Math.ceil(hours / 24);
        return pluralize(days, 'day');
    },

    getEventCardFields(event, translations, params = {}) {
        const firstOccurrence = this.getActualOccurrence(event);
        const firstAudience = event.audiences?.[0];
        const eventType = this.getEventType(event);
        let date = null;
        let duration = null;

        if (firstOccurrence) {
            date = this.convertDate(
                firstOccurrence.begins_at,
                eventType,
                event.timezone,
                params.locale,
                'MMM d, yyyy, h:mm a',
            ).formatted;
            duration = this.calculateDurationToString(firstOccurrence.begins_at, firstOccurrence.ends_at);
        }

        const labels = [];

        if (event.is_third_party) {
            labels.push({
                title: translations.thirdParty,
                type: 'event-category-dark',
            });
        }

        if (firstAudience) {
            const audienceTitle = translations.forValueTitle.replace('@value', firstAudience.title);

            labels.push({
                title: audienceTitle,
                type: 'target-audience',
                color: firstAudience.color,
            });
        }

        if (!event.is_third_party && event.categories?.length) {
            const category =
                (params.categoryId !== undefined && event.categories.find((x) => x.id === params.categoryId)) ||
                event.categories[0];

            labels.push({
                title: category.title,
                type: 'event-category-dark',
            });
        }

        const language = translations.languageTitle.replace('@value', event.language.name);

        return {
            link: `/event/${event.slug}/`,
            type: translations.types[eventType],
            id: event.id,
            title: event.name,
            location: event.place_name,
            imageId: event.cover.image_id,
            date,
            duration,
            labels,
            language,
        };
    },

    formatDate(timestamp, formatString = 'MMMM d, yyyy') {
        if (!timestamp) return null;
        return format(parseISO(timestamp), formatString);
    },
};
